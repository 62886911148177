import type { CallAbiContract } from '~/types/Web3Provider';
import useAbiAccess from './useAbiAccess';
import { useWeb3ModalAccount } from '@web3modal/ethers/vue';
import { TransactionReceipt, formatEther } from 'ethers';
import { useEnvs, useMainStore } from '#imports';
import { Tokens, tokensConfig } from '~/utils/constants';

export default () => {
  const { getMethodCall, getContract } = useAbiAccess();
  const store = useMainStore();
  const { blockchain } = useEnvs();
  const { address } = useWeb3ModalAccount();

  /**
   * invoke a constant (read-only) method
   *
   * @return - method-specific data format
   */
  const callContractMethod = async (callParams: CallAbiContract) => {
    const method = await getMethodCall(callParams);
    const methodArguments = callParams?.methodArguments || [];

    return method?.staticCall(...methodArguments);
  };

  const lp = () => {
    return callContractMethod({
      contract: tokensConfig[store.currentToken].name.store,
      address: blockchain.contracts[store.currentToken].addresses.store,
      methodName: 'lp'
    });
  };

  const lockPeriod = (tokenName: Tokens = store.currentToken) => {
    const map: Record<string, CallAbiContract> = {};

    Object.values(Tokens).forEach((item) => {
      Object.assign(map, {
        [item]: {
          contract: tokensConfig[item].name.yield,
          address: blockchain.contracts[item].addresses.yield,
          methodName: 'lockPeriod',
          methodArguments: []
        }
      });
    });

    Object.assign(map, {
      [Tokens.blackPearl]: {
        contract: 'blackPearlVesting',
        address: blockchain.contracts.blackPearlVesting,
        methodName: 'cliff'
      }
    });

    return callContractMethod(map[tokenName]);
  };

  const unlockable = async (address: string, index: number, tokenName: Tokens = store.currentToken) => {
    const map: Record<string, CallAbiContract> = {};

    Object.values(Tokens).forEach((item) => {
      Object.assign(map, {
        [item]: {
          contract: tokensConfig[item].name.yield,
          address: blockchain.contracts[item].addresses.yield,
          methodName: 'unlockable',
          methodArguments: [address, index]
        }
      });
    });

    Object.assign(map, {
      [Tokens.blackPearl]: {
        contract: 'blackPearlVesting',
        address: blockchain.contracts.blackPearlVesting,
        methodName: 'withdrawableAmount',
        methodArguments: [address, index]
      }
    });

    const amount = await callContractMethod(map[tokenName]);

    return formatEther(amount);
  };

  const unlockPeriod = async (tokenName: Tokens = store.currentToken) => {
    const map: Record<string, CallAbiContract> = {};

    Object.values(Tokens).forEach((item) => {
      Object.assign(map, {
        [item]: {
          contract: tokensConfig[item].name.yield,
          address: blockchain.contracts[item].addresses.yield,
          methodName: 'unlockPeriod',
          methodArguments: []
        }
      });
    });

    Object.assign(map, {
      [Tokens.blackPearl]: {
        contract: 'blackPearlVesting',
        address: blockchain.contracts.blackPearlVesting,
        methodName: 'unlockPeriod'
      }
    });

    return callContractMethod(map[tokenName]);
  };

  const availableTokens = async (address: string, tokenAddress: string) => {
    const amount = await callContractMethod({
      contract: 'erc20',
      address: tokenAddress,
      methodName: 'balanceOf',
      methodArguments: [address]
    });

    return formatEther(amount);
  };

  const allowance = async (address: string, currencyAddress: string, storeTokenName?: Tokens) => {
    const contractAddr = blockchain.contracts[storeTokenName || store.currentToken].addresses.store;

    const amount = await callContractMethod({
      contract: 'erc20',
      address: currencyAddress,
      methodName: 'allowance',
      methodArguments: [address, contractAddr]
    });

    return formatEther(amount);
  };

  const totalStake = async (tokenName: Tokens = store.currentToken) => {
    const amount = await callContractMethod({
      contract: tokensConfig[tokenName].name.yield,
      address: blockchain.contracts[tokenName].addresses.yield,
      methodName: 'totalSupply'
    });

    return formatEther(amount);
  };

  const tokenMaxTotalSupply = async (tokenName: Tokens = store.currentToken) => {
    return callContractMethod({
      contract: tokensConfig[tokenName].name.contract,
      address: blockchain.contracts[tokenName].addresses.contract,
      methodName: 'totalSupply'
    });
  };

  const staked = async (address: string, tokenName: Tokens = store.currentToken) => {
    const map: Record<string, CallAbiContract> = {};

    Object.values(Tokens).forEach((item) => {
      Object.assign(map, {
        [item]: {
          contract: tokensConfig[item].name.yield,
          address: blockchain.contracts[item].addresses.yield,
          methodName: 'balanceOf',
          methodArguments: [address]
        }
      });
    });

    Object.assign(map, {
      [Tokens.blackPearl]: {
        contract: 'blackPearlVesting',
        address: blockchain.contracts.blackPearlVesting,
        methodName: 'balanceOf',
        methodArguments: [address]
      }
    });

    const amount = await callContractMethod(map[tokenName]);

    return formatEther(amount);
  };

  const getLocks = async (address: string, tokenName: Tokens = store.currentToken) => {
    const map: Record<string, CallAbiContract> = {};

    Object.values(Tokens).forEach((item) => {
      Object.assign(map, {
        [item]: {
          contract: tokensConfig[item].name.yield,
          address: blockchain.contracts[item].addresses.yield,
          methodName: 'getLocks',
          methodArguments: [address]
        }
      });
    });

    Object.assign(map, {
      [Tokens.blackPearl]: {
        contract: 'blackPearlVesting',
        address: blockchain.contracts.blackPearlVesting,
        methodName: 'getLocks',
        methodArguments: [address]
      }
    });

    const amount = await callContractMethod(map[tokenName]);

    return formatEther(amount);
  };

  const earned = async (address: string) => {
    const amount = await callContractMethod({
      contract: tokensConfig[store.currentToken].name.yield,
      address: blockchain.contracts[store.currentToken].addresses.yield,
      methodName: 'earned',
      methodArguments: [address]
    });

    return formatEther(amount);
  };

  const getRewardRate = async () => {
    const amount = await callContractMethod({
      contract: tokensConfig[store.currentToken].name.store,
      address: blockchain.contracts[store.currentToken].addresses.store,
      methodName: 'rewardRate'
    });

    return formatEther(amount);
  };

  const saleAmount = async (tokenName: Tokens = store.currentToken) => {
    const amount = await callContractMethod({
      contract: tokensConfig[tokenName].name.store,
      address: blockchain.contracts[tokenName].addresses.store,
      methodName: 'saleAmount'
    });

    return formatEther(amount);
  };

  const liquidityAmount = async () => {
    const amount = await callContractMethod({
      contract: tokensConfig[store.currentToken].name.store,
      address: blockchain.contracts[store.currentToken].addresses.store,
      methodName: 'liquidityAmount'
    });

    return formatEther(amount);
  };

  const getReleaseAmount = async (receipt: TransactionReceipt) => {
    const currentTokenYield = await getContract(
      tokensConfig[store.currentToken].name.contract,
      blockchain.contracts[store.currentToken].addresses.yield
    );
    const blackPearlContract = await getContract('blackPearlVesting', blockchain.contracts.blackPearlVesting);
    const currentBlock = receipt.blockNumber;

    const releaseLog = await Promise.any([
      currentTokenYield
        ?.queryFilter(currentTokenYield.filters.StakeRelease(address, null, null), currentBlock - 10000, currentBlock)
        .then((logs: any[]) => logs.find((log: { transactionHash: string }) => log.transactionHash === receipt.hash)),
      blackPearlContract
        ?.queryFilter(
          blackPearlContract.filters.BlackPearlRelease(address, null, null),
          currentBlock - 10000,
          currentBlock
        )
        .then((logs: any[]) => logs.find((log: { transactionHash: string }) => log.transactionHash === receipt.hash))
    ]);

    if (releaseLog && 'args' in releaseLog) {
      return formatEther(releaseLog.args?.amount ?? '0');
    }

    return null;
  };

  const getUnlockAmount = async (receipt: TransactionReceipt) => {
    const currentTokenYield = await getContract(
      tokensConfig[store.currentToken].name.contract,
      blockchain.contracts[store.currentToken].addresses.yield
    );
    const blackPearlContract = await getContract('blackPearlVesting', blockchain.contracts.blackPearlVesting);
    const currentBlock = receipt.blockNumber;

    const releaseLog = await Promise.any([
      currentTokenYield
        ?.queryFilter(currentTokenYield.filters.StakeUnlock(address, null, null), currentBlock - 10000, currentBlock)
        .then((logs: any[]) => logs.find((log: { transactionHash: string }) => log.transactionHash === receipt.hash)),
      blackPearlContract
        ?.queryFilter(
          blackPearlContract.filters.BlackPearlWithdraw(address, null, null),
          currentBlock - 10000,
          currentBlock
        )
        .then((logs: any[]) => logs.find((log: { transactionHash: string }) => log.transactionHash === receipt.hash))
    ]);

    if (releaseLog && 'args' in releaseLog) {
      return formatEther(releaseLog.args?.amount ?? '0');
    }

    return null;
  };

  return {
    lp,
    lockPeriod,
    unlockable,
    unlockPeriod,
    availableTokens,
    allowance,
    totalStake,
    tokenMaxTotalSupply,
    staked,
    getLocks,
    earned,
    getRewardRate,
    saleAmount,
    liquidityAmount,
    getReleaseAmount,
    getUnlockAmount
  };
};
